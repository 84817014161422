<template>
  <div id="box">
        <div class="beijingtu">
          <div style="width: 100%; height: 100vh">
            <img alt="" height="100%" src="@/assets/images/bj1.png" width="100%" />
          </div>
        </div>
    <div class="login">
      <el-row>
        <el-col :span="12">
          <div class="login-right">
            <img alt="" src="@/assets/images/left.png" width="82%" />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="login-right" style="margin: 0 auto">
            <div class="lgr-hed">
              <img alt="" src="@/assets/images/tu41.png" width="100%" />
            </div>
            <el-form
              ref="ruleForm"
              :model="form"
              :rules="rules"
              class="demo-ruleForm"
              label-width="90px"
              style="margin-top: 80px"
            >
              <el-row>
                <el-col :span="18">
                  <el-form-item
                    class="el-input-xin"
                    label="登陆账号"
                    prop="username"
                  >
                    <el-input
                      v-model="form.username"
                      class="el-input-xin el-input-xin-jia"
                      clearable
                      placeholder="请输入下单时填写的手机号"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6"> </el-col>
              </el-row>

              <el-row>
                <el-col :span="18">
                  <el-form-item class="el-input-xin" label="密码" prop="password">
                    <el-input
                      v-model="form.password"
                      class="el-input-xin el-input-xin-jia"
                      maxlength="16"
                      placeholder="请输入密码"
                      show-password
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6"> </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item class="el-input-xin">
                    <a-button
                      class="form-btn"
                      style="width: 100%; background: #f39800"
                      type="primary"
                      @click="submitForm('ruleForm')"
                    >
                      马上登录
                    </a-button>
                  </el-form-item>
                </el-col>
                <el-col :span="8"> </el-col>
              </el-row>
            </el-form>
            <div style="display: flex;justify-content: flex-end;padding-right: 50px;box-sizing: border-box;margin-top: 20px;color: #409EFF;cursor:pointer;" >
              <div @click="homepay()" style="text-align: left;width: 50%">忘记密码</div>
              <div @click="register()" style="text-align: right;width: 50%">没有账号点此注册</div>
            </div>
          </div>
        </el-col>
      </el-row>
        <div style="position: absolute;bottom: -200px; width: 100%;height: 50px;
        display: flex;justify-content: space-between;">
          <div>
              <div style="display: flex; justify-content: center">
                  <div >
<!--                      <img alt="" src="@/assets/images/footer.png" width="25px" />-->
                  </div>
                  <div style="width: 10px; height: 10px"></div>
                  <div >
                    <span style="color: #FFFFFF;line-height: 25px"><a style="color: #fff;" target="_blank" :href="'https://beian.miit.gov.cn/#/Integrated/index'">{{ configdata.sitebeian }}</a></span>
                  </div>
              </div>
          </div>
          <div>
              <div style="display: flex; justify-content: center">
                  <div>
                      <img alt="" src="@/assets/images/bai1.png" width="25px" />
                  </div>
                  <div style="width: 10px; height: 30px"></div>
                  <div>
                      <span style="color: #FFFFFF">{{ configdata.maile }}</span>
                  </div>
              </div>
          </div>
          <div>
              <div style="display: flex; justify-content: center">
                  <div>
                      <img alt="" src="@/assets/images/bai2.png" width="25px" />
                  </div>
                  <div style="width: 10px; height: 10px"></div>
                  <div>
                      <span style="color: #FFFFFF">{{ configdata.servicephone }}</span>
                  </div>
              </div>
          </div>
        </div>
    </div>




  </div>
</template>

<script>
import {Company_getInfo, conf_list, sendloginemail, userlogin} from "@/api/manage";
import {setToken} from "@/utils/auth";

export default {
  name: "paytest",
  props: {},
  data() {
    return {
      valiBtn: "获取验证码",
      disabled: false,
      show: true,
      count: "",
      timer: null,
      http: true,
      configdata: {},
      projectname: "",
      billstatus: 0,
      company: "",
      drawer: 1,
      endtime: null,
      id: "",
      page: 1,
      pagesize: 10,
      service: "all",
      starttime: null,
      status: 0,
      rescode: null,
      countdown: 10,
      yscode: "",
      codestate: 100,
      Index: 0,
      email: "",
      imgurlas: "",
      sIndexs: 1,
      form: {
        username: "",
        password: "",
        email: "",
        code: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入下单时填写的手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请填写密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.http = window.location.hostname == "salekey.uniondept.com" ? false : true;
    this.httplogo = "/imgs/" + window.location.hostname + "/logo.png";
    this.usbkeyimg = "/imgs/" + window.location.hostname + "/key.jpg";
    conf_list({}).then((response) => {
      var obj = response.data;
      obj = JSON.stringify(obj);
      localStorage.setItem('configdata', obj)
      this.configdata = response.data;
    });
  },
  methods: {
    register(){
      this.$router.push("/register");
    },
    homepay() {
      this.$router.push("/modify");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
       if (this.form.username == "") {
          this.$message.info("请填写登陆账号");
          return false;
        } else  if (this.form.password == "") {
         this.$message.info("请填写密码");
         return false;
       }else {
         userlogin({
           username: this.form.username,
           password: this.form.password,
         }).then((response) => {
           if(response.code == 200){
             // setToken(response.token)
             localStorage.setItem('setToken', response.token)
             this.$router.push("/orderlist");
             this.$message({
               message: '登录成功！',
               type: 'success'
             });
             Company_getInfo({}).then(response => {
               var obj = response.user;
               obj = JSON.stringify(obj);
               localStorage.setItem('companyinfo', obj)
             })
           }
         })
        }
      });
    },

    login: function () {
      console.log(this.form, "来了");
    },
    verification(codes) {
      var mailbox = this.form.email;
      if (
        !/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(
          mailbox
        )
      ) {
        this.$message.info("请填写正确邮箱！！！");
        return false;
      } else {
        let time = 60;
        let timer = setInterval(() => {
          if (time == 0) {
            clearInterval(timer);
            this.valiBtn = "获取验证码";
            this.disabled = false;
          } else {
            this.disabled = true;
            this.valiBtn = time + "秒后重试";
            time--;
          }
        }, 1000);
        sendloginemail({ email: mailbox, yscode: this.form.username }).then(
          (response) => {
            console.log();
            if (response.code == 0) {
              const TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            }
            let timer = setInterval(() => {
              if (time == 0) {
                clearInterval(timer);
                this.valiBtn = "获取验证码";
                this.disabled = false;
              } else {
                this.disabled = true;
                this.valiBtn = time + "秒后重试";
                time--;
              }
            }, 1000);
          }
        );
      }
    },
    youxiang() {
      // console.log("65565")
      window.open("https://qy.163.com/login/?from=ym");
    },
  },
};
</script>

<style lang="css" scoped>
@import "./payTest.css";

b {
  color: #333;
}

body {
  width: 100%;
  height: 100%;
  //background: url("../../assets/images/beijing.png") no-repeat;
}

.bg-img {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  /*background: url("../../assets/images/beijing.png") no-repeat;*/
  background-position-x: -150px;
}

.wpss {
  display: flex;
  /*background: url("../../assets/images/beijing.png") no-repeat;*/
  /*background-position-x: -150px;*/
  height: 110px;
  line-height: 110px;
  text-align: center;
}

.biaoti {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  font-family: "黑体";
  color: #333333;
}

.shangpinbiaoti {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
}

.ant-carousel >>> .slick-dots {
  height: auto;
}

.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 60%;
}

.ant-carousel >>> .slick-thumb {
  bottom: -45px;
}

.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}

.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

#ok .ant-form-item-label label::after {
  content: "";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.displayssssss {
  display: flex;
  flex-direction: column;
  height: 100px;
  position: relative;
}

.displayssssss p {
  float: right;
  width: 300px;
  text-align: right;
  position: absolute;
  right: 0;
}

/*                               */
/**/
.login {
  flex: 7;
  display: flex;
  /* flex-direction: column; */
  width: 1000px;
  height: 497px;
  background-color: #ffffff;
  box-shadow: 5px 0px 20px 0px #e9f3ff;
  position: fixed;
  left:50%;
  top: 19%;
  margin-left: -500px;

}

.login-left {
  width: 500px;
  background-image: url("../../assets/images/left_login.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.login-right {
  width: 500px;
  font-size: 14px;
    margin-left: -1px;
    margin-top: -1px;
}

.lgr-hed {
  width: 68%;
  height: 30px;
  margin-top: 50px;
  margin-left: 25px;
}

.form-box {
  display: block;
  width: 300px;
  height: 50px;
  margin: 0 auto;
  margin-top: 20px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #dbdbdb;
}

.form-btn {
  display: block;
  width: 300px;
  height: 50px;
  margin: 0 auto;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
  background-image: url("../../assets/images/bg_btn.png");
  /* background-repeat: no-repeat; */
  background-size: cover;
  border: 0;
}

.form-code {
  position: absolute;
  right: 60px;
  top: -20px;
  width: 112px;
  height: 22px;
  font-size: 12px;
  font-family: SourceHanSansCN-Light, SourceHanSansCN;
  font-weight: 300;
  color: #3381ff;
  line-height: 129px;
  cursor: pointer;
  font-weight: bold;
}

.form-codes {
  position: absolute;
  right: 90px;
  top: -20px;
  width: 112px;
  height: 22px;
  font-size: 12px;
  font-family: SourceHanSansCN-Light, SourceHanSansCN;
  font-weight: 300;
  color: #999999;
  line-height: 129px;
  cursor: pointer;
  font-weight: bold;
}

.foot {
  flex: 2;
  width: 500px;
  text-align: center;
  margin: auto;
  font-size: 12px;
  font-family: SourceHanSansCN-Light, SourceHanSansCN;
  font-weight: 300;
  color: #333333;
  line-height: 30px;
  letter-spacing: 1px;
}

.foot-sp {
  cursor: pointer;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.beijingtu {
  width: 100%;
  height: 100vh;
  /*background-image: url("../../assets/images/beijing3.png");*/
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: fixed;
}

.textbiaoti {
  width: 800px;
  height: 110px;
}

.ziti {
  margin-top: 32px;
  margin-left: 10px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  font-family: "黑体";
  color: #333333;
}

.ant-row {
  margin-left: 24px;
}
</style>
